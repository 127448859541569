import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import { getBaseUrl } from 'src/utils'
import PageHelmet from 'src/components/PageHelmet'
import Footer from 'src/components/Footer'
import Header from 'src/components/Header'
import withLayout from 'src/components/withLayout'
import NewsList from 'src/components/NewsList'

class SingleNews extends Component {
  render() {
    const {
      data: {
        news: { edges: newsEdges },
        singleNews: { title },
        promotion,
      },
      pageContext: { originalPath },
      locale,
      changeLocale,
    } = this.props

    const news = newsEdges.filter(({ node }) => node.slug !== '_')
    const indexOfFirstNews = news.findIndex(({ node: { title: newstitle } }) => newstitle === title)
    const removed = news.splice(indexOfFirstNews, 1)
    const preparedNews = [...removed, ...news]

    return (
      <Layout locale={locale}>
        <PageHelmet
          title={title}
          url={`${getBaseUrl()}${locale}${originalPath}`}
          image={removed[0].node.image && removed[0].node.image.fluid.src}
          defer={false}
        />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <NewsList news={preparedNews} locale={locale} promotion={promotion} />
        <Footer locale={locale} />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String!, $locale: String!) {
    singleNews: datoCmsNews(id: { eq: $id }) {
      title
    }

    news: allDatoCmsNews(filter: { locale: { eq: $locale } }, sort: { fields: [publishdate], order: DESC }) {
      edges {
        node {
          publishdate(formatString: "DD.MM.YYYY")
          slug
          title
          description
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            id
            fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          author
        }
      }
    }

    promotion: allDatoCmsPromotion(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          autoplayDelay
          slides {
            id
            title
            image {
              url
            }
            link
          }
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'singleNews',
}

export default withLayout(customProps)(SingleNews)
